<template>
  <div class="header py-2 transition-all duration-200 z-10" :class="{ 'fixed shadow-soft top-0 left-0 w-full bg-white': scrollPosition > 100 }">
    <div class="container flex justify-between items-center">
      <router-link to="/" class="mdmax:w-28 mdmax:inline-block w-56">
        <img src="~@/assets/images/logo/edutest.png" />
      </router-link>
      <div class="flex items-center gap-8">
        <router-link :to="menuItem.link" v-for="(menuItem, index) in menu" :key="'menu-' + index" class="font-bold">{{ menuItem.title }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { menu } from '@/constants'
export default {
  data() {
    return {
      scrollPosition: null
    }
  },
  computed: {
    menu() {
      return menu
    }
  },
  created() {
    window.addEventListener('scroll', this.updateScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  }
}
</script>
