<template>
  <div class="header fixed max-w-full top-0 left-0 w-full px-4 py-2 z-60 transition-all overflow-hidden" :class="{ 'bg-white shadow-soft': scrollPosition > 0 }">
    <div class="container flex justify-between items-center">
      <router-link to="/" class="mdmax:w-28 mdmax:inline-block flex-shrink-0">
        <!-- <Logo width="43" height="40" /> -->
        <img src="~@/assets/images/logo/edutest.png" class="w-28 flex-shrink-0" />
      </router-link>
      <div @click="toggleSidebar()" class="flex-shrink-0"><Hamburger /></div>
    </div>
    <div class="sidebar-backdrop z-50" @click="close()" v-if="isOpen"></div>
    <div class="sidebar gap-8 z-70 w-80 fixed top-0 bottom-0 bg-white" :class="[isOpen ? 'right-0' : '-right-full']">
      <div class="text-right flex justify-end p-2 mb-4">
        <span @click="close()"><Close /></span>
      </div>
      <div class="flex flex-col px-4 gap-3">
        <router-link :to="menuItem.link" v-for="(menuItem, index) in menu" :key="'menu-' + index" class="shadow-border-b pb-3" @click="close()">{{ menuItem.title }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { menu } from '@/constants'
export default {
  components: {
    // Logo: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Logo'),
    Hamburger: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Hamburger'),
    Close: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Close')
  },
  data() {
    return {
      isOpen: false,
      scrollPosition: null
    }
  },
  computed: {
    menu() {
      return menu
    }
  },
  created() {
    window.addEventListener('scroll', this.updateScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen
    },
    close() {
      this.isOpen = false
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar {
  transition: all 250ms ease-in-out !important;
}
.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
}
.z-70 {
  z-index: 70;
}
</style>
