const menu = [
  {
    title: 'Beranda',
    link: '#beranda'
  },
  {
    title: 'Fitur',
    link: '#fitur'
  },
  // {
  //   title: 'Biaya',
  //   link: '#biaya'
  // },
  {
    title: 'Layanan Pengaduan',
    link: '#faq'
  }
]
export default menu
